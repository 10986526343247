<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        <v-img style="max-width: 400px" src="/img/assignments/fish.png" />
      </p>

      <p class="mb-2">Are fat fish more likely to have broad stripes than thin fish?</p>
      <p class="mb-4">
        <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-0 ml-3">
          <v-radio
            v-for="option in options1"
            :key="option.value"
            :value="option.value"
            class="my-3"
          >
            <template #label>
              <stemble-latex :content="option.text" />
            </template>
          </v-radio>
        </v-radio-group>
      </p>

      <h3><u>Reason</u></h3>
      <p class="mb-2">
        <v-radio-group v-model="inputs.input2" :disabled="!allowEditing" class="mb-0 ml-3">
          <v-radio
            v-for="option in options2"
            :key="option.value"
            :value="option.value"
            class="my-3"
          >
            <template #label>
              <stemble-latex :content="option.text" />
            </template>
          </v-radio>
        </v-radio-group>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'UPEIToTLItem7',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
      options1: [
        {text: 'Yes', value: 'yes'},
        {text: 'No', value: 'no'},
      ],
      options2: [
        {
          text: 'Some fat fish have broad stripes and some have narrow stripes.',
          value: 'one',
        },
        {
          text: '3/7 of the fat fish have broad stripes.',
          value: 'two',
        },
        {
          text: '12/28 are broad striped and 16/28 are narrow striped.',
          value: 'three',
        },
        {
          text: '3/7 of the fat fish have broad stripes and 9/21 of the thin fish have broad stripes.',
          value: 'four',
        },
        {
          text: 'Some fish with broad stripes are thin and some are fat.',
          value: 'five',
        },
      ],
    };
  },
};
</script>
<style scoped></style>
